import buildGoogleWebfontUrl from './buildGoogleWebfontUrl'
import { useEffect, useMemo } from 'react'
function useGoogleFonts({ fontFamily, fontWeight }: { fontFamily?: string; fontWeight?: string }) {
  const href = useMemo(() => buildGoogleWebfontUrl(fontFamily, fontWeight), [fontFamily, fontWeight])
  useEffect(() => {
    if (!href) return
    const link = document.createElement('link')
    link.href = href
    link.rel = 'stylesheet'

    document.head.appendChild(link)

    return () => {
      document.head.removeChild(link)
    }
  }, [href])
}

export default useGoogleFonts
