export default function buildGoogleWebfontUrl(fontFamily = '', fontWeight = '') {
  if (!fontFamily) return

  // For the GoogleFonts Api, we need fontWeight as number.
  // This takes care of possible legacy values out there.
  let fontWeightAsNumber
  switch (fontWeight) {
    case 'bold':
      fontWeightAsNumber = 700
      break
    case 'light':
      fontWeightAsNumber = 300
      break
    default:
      fontWeightAsNumber = Number.parseInt(fontWeight) || 400
  }

  const fontFamilyForUrl = `?family=${fontFamily.replace(/ /g, '+')}`
  const fontWeightForUrl = fontWeight ? `:wght@${fontWeightAsNumber}` : ''

  return `https://fonts.googleapis.com/css2${fontFamilyForUrl}${fontWeightForUrl}`
}
